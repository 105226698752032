import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';

import { css } from 'astroturf';

const styles = css`
  .changelogcontainer {
    & > div + div {
      margin-top: 4rem;
    }
    & h2 {
      & span {
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        font-size: 0.75rem;
        margin-top: -2px;
        line-height: 1;
        color: #fff;
        background: #000;
        padding: 5px 6px;
        border-radius: 30px;
        font-weight: 400;
      }
    }
    & ul,
    & p {
      margin-top: 1rem;
    }

    & ul {
      margin-left: 1rem;
    }
  }
`;

const URL = `https://api.github.com/repos/firstversionist/polypane/releases?per_page=100`;

const Changelog = () => {
  const [changelog, setChangelog] = useState('Loading...');

  async function getChangelog() {
    const releases = await fetch(URL).then(response => response.json());

    setChangelog(
      releases.map(release => {
        const releaseDate = new Date(release.published_at);
        return (
          <div className={styles.release} key={release.name}>
            <h2>
              {release.name}{' '}
              <span>
                {releaseDate.toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </span>
            </h2>
            <div className={styles.desc}>
              <Markdown>{release.body}</Markdown>
            </div>
          </div>
        );
      })
    );
  }

  useEffect(() => {
    getChangelog();
  }, []);

  return (
    <section className={styles.changelog}>
      <div className={styles.changelogcontainer}>{changelog}</div>
    </section>
  );
};

export default Changelog;
