import { css } from 'astroturf';
import React from 'react';

import CTA from '../../components/CTA';
import Changelog from '../../components/Changelog';
import DocHeader from '../../components/DocHeader';
import DocsListing from '../../components/DocsListing';
import SEO from '../../components/SEO';
import DocSupport from '../../components/docs/support';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .docContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
    @media (min-width: 40rem) {
      flex-direction: row;
      align-items: flex-start;
    }
  }

  .menu {
    position: sticky;
    top: 70px;
    padding-top: 10px;
    z-index: 9;

    & > ul {
      display: none;
    }

    & button {
      font-weight: 400;
      display: inline-block;
      height: 30px;
      line-height: 28px;
      border-radius: 30px;
      font-size: 1rem;
      padding: 0 1rem;
      background: #107db5;
      color: #fff;
      border: 1px solid #107db5;
      transition: 0.25s ease-out all;
      margin: 0 0 0.5rem;
      &:hocus {
        background: #fff;
        color: #107db5;
      }
    }
    @media (min-width: 40rem) {
      position: relative;
      top: 0;
      overflow-y: visible;
      max-height: initial;
      flex: 1;
      display: block;

      & > button {
        display: none;
      }
      & > ul {
        display: block;
      }
    }
  }

  .open {
    & > ul {
      background: #fff;
      display: block;
      overflow: auto;
      max-height: calc(100vh - 118px);
    }
  }

  .content {
    flex: 2;

    & > *:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media (min-width: 40rem) {
      padding-left: 1.5rem;
    }
  }
  .dirNav {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;

    & svg {
      vertical-align: text-bottom;
      transform: translateY(-2px);
    }
  }
`;

class Index extends React.Component {
  constructor() {
    super();

    this.state = {
      menuShown: false,
    };
  }

  showMenu = () => {
    const { menuShown } = this.state;
    this.setState({ menuShown: !menuShown });
  };

  render() {
    const menuClasses = [styles.menu, this.state.menuShown ? styles.open : undefined].filter((x) => x).join(' ');

    return (
      <Layout>
        <SEO title="Changelog" keywords={['']} pathname={this.props.location.pathname} />
        <DocHeader />
        <div className="widePage">
          <div className={styles.docContainer}>
            <div className={menuClasses}>
              <button type="button" onClick={this.showMenu}>
                {this.state.menuShown ? 'Hide menu' : 'Show menu'}
              </button>
              <DocsListing />
            </div>
            <section className={styles.content}>
              <h1 id="polypane-changelog">Polypane Changelog</h1>
              <div className="childPadding">
                <p>
                  Polypane is updated roughly every month with new features, bug fixes and other improvements. Find the
                  changelogs for all versions below.
                </p>
                <Changelog />
              </div>
              <DocSupport />
            </section>
          </div>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Index;
